body {
    font-family: silkscreen;
}

button {
    font-family: silkscreen;
    background-color: white;
}

.hidden {
    display: none;
}

.active {
    background-color: #DDDDDD   ;
}

.mainContainer {
    max-height: 100vh;
    justify-items: center;
    display: grid;
    


}

.mainrow {
    height: 70vh;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 4fr;
    

    grid-gap: 1PX;
}

.mainrow > div {
    overflow: scroll;
}

.navbar {
    display: flex;
    justify-content: center;
    border-bottom: solid rgb(240, 240, 240);
    padding: 2vw;
    margin: 0px;
}

.table {
    table-layout: fixed;
    width: 100%;
    font-size: 80%;
}

.table > tbody > tr {
    max-width: 100%;
    
}

.table > tbody > tr > td{
    overflow: hidden;
}

th, td {
    padding: 0.25rem;
    text-align: center;
    border: 1px solid #ccc;
  }
  tbody tr:hover {
   background-color: rgb(240, 240, 240);
  }

.logo {
    width: 30% ;
    margin: auto;
    align-self: center;
}

